<template>
  <b-container class="mt-4 mb-4" fluid>
    <h5 class="fw-bold mb-3">{{ form.name }}</h5>
    <b-row>
      <b-col cols="12" md="6" xl="4" v-for="(submitted_form, index) in information_sent.data" :key="index">
        <b-card class="justify-content-center bs-br mb-3">
          <b-row>
            <b-col cols="12" v-for="(input, index) in submitted_form.input_values" :key="index">
              <label class="fw-bold">{{ form.fields.find(field => field.id == input.field_id).description }}:</label> {{ input.value }}
            </b-col>
            <b-col cols="12">
              <label class="fw-bold">Sent date:</label>
              {{ new Date(submitted_form.created_at).toLocaleDateString('en-US')}}
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="information_sent.pagination.current_page"
          pills align="right"
          :total-rows="information_sent.pagination.total"
          :per-page="information_sent.pagination.per_page"
          @change="getSubmittedForm"
          v-if="information_sent.data.length > 0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'information_sent',
  data() {
    return {
      form: [],
      information_sent: {
        data: [],
        pagination: {
          current_page: 0,
          per_page: 0,
          total: 0
        }
      },
      load: false
    };
  },
  mounted() {
    this.getForm();
    this.getSubmittedForm();
  },
  methods: {
    getSubmittedForm(page){
      this.load = true;
      this.$http.get(`submitted_forms/${this.$route.params.form}/${this.$route.params.email}`, { params: {page: page}, headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.information_sent = response.body.data;
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
        }
      })
    },
    getForm(){
      this.$http.get(`marketing/forms/${this.$route.params.form}`, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
        if (response.status === 200) {
          this.form = response.body.data;
        }
        this.load = false;
      }, (error) => {
        if (error.status == 500) {
          this.$notify({ group: 'app', text: 'An error occurred while creating the user. Please try again.', type: 'error' });
        }
        this.load = false;
      })
    },
  }
};
</script>
